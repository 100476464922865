import { HomeIcon } from "@heroicons/react/solid";
import { getAccountRoleFallbackRoute } from "@utils/routes";
import { Link, TbTLogo } from "components/shared";
import { useAuth } from "contexts/AuthProvider";

export default function Custom404() {
  const { accountRole } = useAuth();

  return (
    <div className="flex flex-col flex-center min-h-screen py-2 text-center">
      <main className="flex flex-col flex-center space-y-12">
        <TbTLogo />
        <h1 className="text-4xl font-medium">404 - Page Not Found</h1>
        <p className="text-2xl">
          <Link route={getAccountRoleFallbackRoute(accountRole)}>
            <span className="flex space-x-2 items-center">
              <span>Go Home</span>
              <HomeIcon className="w-8 h-8" />
            </span>
          </Link>
        </p>
      </main>
    </div>
  );
}
